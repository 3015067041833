import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Story = () => {
  const {
    markdownRemark: { html },
  } = useStaticQuery(graphql`
    query {
      markdownRemark {
        html
      }
    }
  `)

  return (
    <div
      className="blog-post-content"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Story" />
    <Story />
  </Layout>
)

export default IndexPage
